import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { LeftToolBar } from "./LeftToolBar";
import PanelConTabs from "./PanelConTabs";
import DynamicForm from "./panelTabs/DynamicForm";
import DynamicEdge from "./panelTabs/DynamicEdge";
import { genId } from "../../utils/idGenerator";
import InnerGraph from "./InnerGraph";
import { useVoicebotStore } from "../../store/voicebotStore";
import { useNodeTypesStore } from "../../store/nodeTypesStore";
import { getTypedData, validarCampos } from "../../utils/functionsUtils";

const GraphComponent = () => {
  const toast = useRef(null);

  const {
    elements,
    setElements,
    estados,
    setEstados,
    addElement,
    getElement,
    updateElementProp,
    addEstado,
    updateStateNodeProp,
    nodeTrackers,
    addNodeTracker,
    updateNodeTracker,
    getNodeTracker,
    updateNodeTrackerProp,
    removeNodeTracker,
    voicebot,
    updateVoicebotProp,
  } = useVoicebotStore((state) => state);

  const { nodeTypes, getNodeType } = useNodeTypesStore((state) => state);

  const [tabSeleccionada, setTabSeleccionada] = useState("Voicebot");

  const cambiarTab = (nuevoTab) => {
    setTabSeleccionada(nuevoTab);
  };

  useEffect(() => {
    if (elements.length === 0) cambiarTab("Voicebot");
  }, [elements]);

  const [node, setNode] = useState(null);
  const [edge, setEdge] = useState(null);

  const selectNode = (data) => {
    setTabsNull();
    setNode(data);
    setTabSeleccionada("Nodo");
  };

  const selectEdge = (data) => {
    setTabsNull();
    setEdge(data);
    setTabSeleccionada("Flecha");
  };

  const setTabsNull = () => {
    setNode(null);
    setEdge(null);
  };

  const setFirstNode = (nodeId) => {
    if (voicebot.firstQuestion === null) {
      updateVoicebotProp("firstQuestion", nodeId);
    }
  };

  const addNode = (type) => {
    const _id = genId();
    /** */
    const _flechas = getFlechas(type);
    const _nodeTracker = {
      id: _id,
      flechas: _flechas,
      disponibles: _flechas.length,
      validForm: true,
    };
    /** */
    const _dif = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;

    const newNode = {
      data: {
        type: type,
        name: "Nodo-" + _dif,
        id: _id,
        color: nodeTypes.find((nodo) => nodo.type === type).color,
        icon: nodeTypes.find((nodo) => nodo.type === type).icon,
        defaultNextQuestion: null,
        hangUpNextQuestion: null,
      },
      position: {
        x: 200,
        y: 200,
      },
      group: "nodes",
    };

    setFirstNode(_id);

    // mapeo de form
    const filteredElements = nodeTypes.filter(
      (element) => element.type === type
    );
    const formElement = filteredElements[0];

    if (formElement.form && Array.isArray(formElement.form)) {
      formElement.form.forEach((group) => {
        if (group.components && Array.isArray(group.components)) {
          group.components.forEach((component) => {
            if (component.name !== "name") {
              newNode.data[component.name] =
                component.default !== undefined ? component.default : null;
            }
          });
        }
      });
    }
    addNodeTracker(_nodeTracker);
    addElement(newNode);
    trackearForm(_id);
  };

  /** */
  const getType = (id) => {
    const _nodo = getElement(id);

    if (_nodo) {
      return {
        type: _nodo.data.type,
        nombre: _nodo.data.name,
      };
    } else {
      return null;
    }
  };

  const getFlechas = (tipo) => {
    const _nodo = nodeTypes.find((node) => node.type === tipo);

    if (_nodo) {
      const flechasConDisabled = _nodo.flechas.map((flecha) => ({
        ...flecha,
        disabled: false,
      }));
      return flechasConDisabled;
    } else {
      return [];
    }
  };
  /** */
  const getFlechasSource = (nodeId) => {
    const nodeTracker = getNodeTracker(nodeId);
    return nodeTracker ? nodeTracker.flechas : null;
  };

  /** */
  const desactivarFlecha = (source, _flechas) => {
    const element = _flechas.find((item) => item.disabled === false);
    if (element) {
      element.disabled = true;
    }
    setearTotal(source);
    return { edgeOption: element, flechas: _flechas };
  };

  const switchearFlechas = (source, _flechas, edgeOptions) => {
    const anterior = edgeOptions.anterior;
    const actual = edgeOptions.actual;

    _flechas.forEach((item) => {
      if (item.name === anterior) {
        item.disabled = false;
      } else if (item.name === actual) {
        item.disabled = true;
      }
    });
    setearTotal(source);
  };

  const setearTotal = (source) => {
    const nodeTracker = getNodeTracker(source);
    if (!nodeTracker) {
      return null;
    }

    const totalDisponibles = nodeTracker.flechas.filter(
      (flecha) => !flecha.disabled
    ).length;

    nodeTracker.disponibles = totalDisponibles;
    updateNodeTracker(source, nodeTracker);
  };

  const eliminarOption = (edges) => {
    edges.forEach((edge) => {
      const _source = edge.data.source;
      const _option = edge.data.edgeOption;

      const nodeTracker = getNodeTracker(_source);
      if (nodeTracker && nodeTracker.flechas) {
        const _flechas = nodeTracker.flechas;

        _flechas.forEach((flecha) => {
          if (flecha.value === _option) {
            flecha.disabled = false;
          }
        });
        updateNodeTrackerProp(_source, "flechas", _flechas);
      }
      setearTotal(_source);
    });
  };
  /** */

  const getEdgeOption = (source, edgesOptions?: null) => {
    const _flechas = getFlechasSource(source);

    if (!edgesOptions) {
      const ret = desactivarFlecha(source, _flechas);
      return ret.edgeOption.name;
    } else {
      switchearFlechas(source, _flechas, edgesOptions);
    }
  };

  const formatEdgeOption = (option) => {
    let formatedOption = option.charAt(0).toUpperCase() + option.slice(1);
    formatedOption = formatedOption.replace(/_/g, " ").replace(/-/g, " ");
    return formatedOption;
  };

  const handleEstados = (source, edgeId) => {
    const _nodo = getType(source);
    const _edgeOption = getEdgeOption(source);

    function maxPonderation() {
      if (!estados.length) return 0;
      return estados.reduce((max, estado) => {
        return estado.ponderacion > max ? estado.ponderacion : max;
      }, 0);
    }

    const _name = formatEdgeOption(_edgeOption);

    const _newEstado = {
      ponderacion: maxPonderation() + 1,
      flechitaId: edgeId,
      questionId: source,
      question: _nodo.nombre,
      flechita: _edgeOption,
      estado: null,
      nombre: _name,
      activeGraphView: false,
    };

    const updatedEstados = [...estados, _newEstado];
    updatedEstados.sort((a, b) => b.ponderacion - a.ponderacion);
    setEstados(updatedEstados);

    return _edgeOption;
  };
  const [nodeSource, setNodeSource] = useState(null);

  const addEdge = (source, target) => {
    if (target) {
      const edgeId = genId();
      const _option = handleEstados(source, edgeId);

      const newEdge = {
        data: {
          type: "00",
          edgeOption: _option,
          id: edgeId,
          source: source,
          target: target,
        },
        group: "edges",
      };

      // agregar el nuevo edge
      addElement(newEdge);
    }
  };

  const tieneFlechasDisponibles = (nodeId) => {
    const nodeTracker = nodeTrackers.find((item) => item.id === nodeId);

    if (!nodeTracker) {
      return false;
    }
    return nodeTracker.disponibles > 0;
  };
  /** */
  const estaAsociado = (node) => {
    const _id = node.data.id;
    return elements.some((element) => {
      const _data = getTypedData(nodeTypes, element.data, [
        "entrenamiento",
        "condicion",
      ]);

      return _data.some((item) => {
        if (item.typeComponent === "entrenamiento") {
          return (
            Array.isArray(item.value) &&
            item.value.some((innerItm) => {
              return (
                Array.isArray(innerItm.nodes) &&
                innerItm.nodes.some((node) => node.idNode === _id)
              );
            })
          );
        } else if (item.typeComponent === "condicion") {
          return (
            Array.isArray(item.value?.nodes) &&
            item.value.nodes.some((node) => node.idNode === _id)
          );
        }
        return false;
      });
    });
  };

  const esFirstNode = (node) => {
    if (voicebot.firstQuestion === node.data.id) {
      return true;
    }
    return false;
  };

  const deleteNode = (node) => {
    if (esFirstNode(node)) {
      toast.current.show({
        severity: "warn",
        summary: "Eliminar nodo",
        detail: "No se puede eliminar porque es el primer nodo",
        life: 3000,
      });
      return;
    }
    if (estaAsociado(node)) {
      toast.current.show({
        severity: "warn",
        summary: "Eliminar nodo",
        detail: "No se puede eliminar el nodo porque está en uso",
        life: 3000,
      });
      return;
    }
    const _id = node.data.id;
    const _elements = [];
    const matchedEdges = [];

    elements.forEach((elemento) => {
      if (elemento.group === "nodes" && elemento.data.id === _id) {
        return false;
      } else if (elemento.group === "edges") {
        if (elemento.data.source === _id || elemento.data.target === _id) {
          matchedEdges.push(elemento);
        } else {
          _elements.push(elemento);
        }
      } else {
        _elements.push(elemento);
      }
    });

    removeNodeTracker(_id);
    deleteEdges(matchedEdges);
    setElements(_elements);
    cambiarTab("Voicebot");
  };

  /** */
  const deleteEdges = (_deletedEdges) => {
    eliminarOption(_deletedEdges);

    const filterElements = (deletedEdges) => {
      const deletedIds = new Set(deletedEdges.map((edge) => edge.data.id));
      return elements.filter((element) => !deletedIds.has(element.data.id));
    };

    const filterEstados = (deletedEdges) => {
      const deletedIds = new Set(deletedEdges.map((edge) => edge.data.id));
      return estados.filter((estado) => !deletedIds.has(estado.flechitaId));
    };

    setElements(filterElements(_deletedEdges));
    setEstados(filterEstados(_deletedEdges));
  };

  /** */
  const reTrackSimple = (element, formArray) => {
    let _validForm = true;

    if (element.data.intention && element.data.intention.simple) {
      if (Array.isArray(element.data.intention.simple.entities)) {
        for (let entity of element.data.intention.simple.entities) {
          if (entity.key === null) {
            _validForm = false;
          }
          if (entity.locution === null) {
            _validForm = false;
          }
        }
      }
    } else {
      _validForm = validarCampos(element, formArray);
    }
    return _validForm;
  };

  const trackearForm = (id) => {
    const element = getElement(id);
    const nodeTracker = getNodeTracker(id);
    const node = getNodeType(element.data.type);

    const formArray = node.form.reduce((acc, curr) => {
      return acc.concat(curr.components);
    }, []);

    /** */
    const _nodeTracker = {
      ...nodeTracker,
      validForm: reTrackSimple(element, formArray),
    };
    updateNodeTracker(element.data.id, _nodeTracker);
  };

  /** */
  const handleElementChange = (propName: string, value: any, id: string) => {
    if (propName === "name") {
      if (estaAsociado(node)) {
        toast.current.show({
          severity: "warn",
          summary: "Editar nodo",
          detail: "No se puede editar el nombre porque el nodo está en uso",
          life: 3000,
        });
        return;
      }
      updateStateNodeProp(id, "question", value);
    }
    /** */
    updateElementProp(id, propName, value);
    trackearForm(id);
  };

  const tabNodeTemplate = (
    <>
      {node && (
        <div className="body" style={{ flexDirection: "column" }}>
          <DynamicForm
            node={node}
            handleElementChange={handleElementChange}
            deleteNode={deleteNode}
            trackearForm={trackearForm}
          />
        </div>
      )}
    </>
  );

  const tabEdgeTemplate = (
    <>
      {edge && (
        <div className="body" style={{ flexDirection: "column" }}>
          <DynamicEdge
            edge={edge}
            deleteEdges={deleteEdges}
            nodeSource={nodeSource}
            setNodeSource={setNodeSource}
            getEdgeOption={getEdgeOption}
            formatEdgeOption={formatEdgeOption}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="graph">
      <Toast ref={toast} />
      <LeftToolBar addNode={addNode} />

      <InnerGraph
        selectEdge={selectEdge}
        selectNode={selectNode}
        addEdge={addEdge}
        tieneFlechasDisponibles={tieneFlechasDisponibles}
      />

      <PanelConTabs
        cambiarTab={cambiarTab}
        tabSeleccionada={tabSeleccionada}
        setTabsNull={setTabsNull}
        tabNodeTemplate={tabNodeTemplate}
        tabEdgeTemplate={tabEdgeTemplate}
      />
    </div>
  );
};

export default GraphComponent;
