import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { genId } from "../../utils/idGenerator";
import { useVoicebotStore } from "../../store/voicebotStore";
import { useFetchLocution } from "../../hooks/useLocution";

const PreguntaInput = ({ id, handleElementChange, name, required }) => {
  const { elements, getElement } = useVoicebotStore((state) => state);

  const [pregunta, setPregunta] = useState(null);
  const [audio, setAudio] = useState(null);
  const [variable, setVariable] = useState("");

  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const [tipoVar, setTipoVar] = useState("texto");
  const optionsTipoVar = [
    { label: "texto", value: "texto" },
    { label: "monto ARS", value: "monto_ars" },
    { label: "monto USD", value: "monto_usd" },
  ];

  const getClass = (data) => {
    if (data.indexOf("texto") !== -1) {
      return "status-renewal";
    }
    if (data.indexOf("mointo_ars") !== -1) {
      return "status-negotiation";
    }
    if (data.indexOf("monto_usd") !== -1) {
      return "status-qualified";
    }
    return "status-new";
  };

  const itemTemplate = (data) => {
    return (
      <span
        className={"customer-badge " + getClass(data.value) + " text-status"}
      >
        {data.label}
      </span>
    );
  };

  const selectedTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge " + getClass(option.value) + " text-status"
          }
          style={{ fontSize: "10px" }}
        >
          {option.label}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  /** */
  const filtrarOptions = (opt) => {
    return opt.map((elemento) => ({
      id: elemento.id,
      audioName: elemento.audioName,
      path: elemento.path,
    }));
  };

  const _filterLocution = {
    rows: 8000,
  };
  const fetchLocution = useFetchLocution(_filterLocution);
  useEffect(() => {
    if (fetchLocution.data) {
      setOptions(filtrarOptions(fetchLocution.data));
    }
    setLoading(fetchLocution.isLoading);
  }, [fetchLocution.data]);

  const pasarAArray = (_preg) => {
    const newArray = [];

    Object.keys(_preg).forEach((index) => {
      const elemento = _preg[index];
      elemento.id = genId();
      newArray.push(elemento);
    });

    return newArray;
  };

  const pasarAObj = (_preg) => {
    const obj = {};

    _preg.forEach((element, index) => {
      obj[index] = {
        label: element.label,
        value: element.value,
        type: element.type,
      };
    });
    return obj;
  };

  const obtenerPregunta = (id, name) => {
    return getElement(id) ? getElement(id).data[name] : [];
  };

  useEffect(() => {
    const _pregunta = pasarAArray(obtenerPregunta(id, name));
    setPregunta(_pregunta);
  }, []);

  const onAudioChange = (value) => {
    setAudio(value);
  };

  const onVariableChange = (value) => {
    setVariable(value);
  };

  const addAudio = () => {
    const _pregunta = pregunta;
    _pregunta.push({
      label: audio.audioName,
      value: `/locutions/${audio.id}.wav`,
      type: "locution",
      id: genId(),
    });

    setPregunta(_pregunta);
    handleElementChange(name, pasarAObj(_pregunta), id);
    setAudio(null);
  };

  const addVariable = () => {
    const _pregunta = pregunta;
    _pregunta.push({
      label: variable,
      value: `/tts/${tipoVar}/@${variable}#`,
      type: "variable",
      id: genId(),
    });

    setPregunta(_pregunta);
    handleElementChange(name, pasarAObj(_pregunta), id);
    setVariable("");
  };

  const eliminarPregunta = (_id) => {
    const _pregunta = pregunta.filter((preg) => preg.id !== _id);
    setPregunta(_pregunta);
    handleElementChange(name, pasarAObj(_pregunta), id);
  };

  return (
    <div
      className="pregunta-input"
      style={{
        borderColor:
          required &&
          elements.find(
            (element) =>
              element.data.id === id &&
              typeof element.data[name] === "object" &&
              element.data[name] !== null &&
              Object.keys(element.data[name]).length === 0
          )
            ? "#f44336"
            : "#ced4da",
      }}
    >
      <div className="quest-column">
        <div className="demi-column field">
          <div className="title">
            <label htmlFor="audio">Audio</label>
            <div className="circulo"></div>
          </div>
          <div className="comp">
            <Dropdown
              value={audio}
              options={options}
              onChange={(e) => onAudioChange(e.value)}
              placeholder={!loading ? "Selecciona" : "Cargando..."}
              optionLabel="audioName"
              className="audio"
              filter
            />
            <Button
              icon="pi pi-plus"
              onClick={() => addAudio()}
              disabled={audio ? false : true}
            />
          </div>
        </div>
        <div className="demi-column field">
          <div className="title">
            <label htmlFor="variable">Variable</label>
            <div className="circulo triple"></div>
          </div>
          <div className="comp">
            <Dropdown
              value={tipoVar}
              options={optionsTipoVar}
              onChange={(e) => setTipoVar(e.value)}
              className="tipo-var"
              valueTemplate={selectedTemplate}
              itemTemplate={itemTemplate}
            />
            <InputText
              value={variable}
              onChange={(e) => onVariableChange(e.target.value)}
              placeholder="Ingresa"
              className="var"
            />
            <Button
              icon="pi pi-plus"
              onClick={() => addVariable()}
              disabled={variable ? false : true}
            />
          </div>
        </div>
      </div>

      {pregunta && (
        <Question
          pregunta={pregunta}
          eliminarPregunta={eliminarPregunta}
          getClass={getClass}
        />
      )}
    </div>
  );
};

export default PreguntaInput;

const Question = ({ pregunta, eliminarPregunta, getClass }) => {
  return (
    <div className="question">
      {pregunta.map((p) => (
        <span key={p} className={`palabra customer-badge ${getClass(p.value)}`}>
          {p.label}
          <button type="button" onClick={() => eliminarPregunta(p.id)}>
            <i className="pi pi-trash"></i>
          </button>
        </span>
      ))}
    </div>
  );
};
