import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InputText, Dropdown, InputSwitch } from "primereact";
import { classNames } from "primereact/utils";
import { MdDragIndicator } from "react-icons/md";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { useVoicebotStore } from "../../../store/voicebotStore";
import { useGraphStore } from "../../../store/graphStore";
import { Toast } from "primereact/toast";

const DynamicVoicebot = () => {
  const toast = useRef(null);

  const { estados, setEstados, voicebot, updateVoicebotProp, elements } =
    useVoicebotStore((state) => state);

  const { drawMode, setDrawMode } = useGraphStore((state) => state);

  useEffect(() => {
    if (drawMode) setDrawMode(false);
  }, []);

  useEffect(() => {
    buildNodes();
  }, [elements]);

  const [nodes, setNodes] = useState(null);
  const buildNodes = () => {
    const filterNodes = () => {
      return elements
        .filter((item) => item.group === "nodes")
        .map((node) => ({
          name: node.data.name,
          id: node.data.id,
        }));
    };

    const _nodes = filterNodes();
    setNodes(_nodes);
  };

  const updateFirstQuestion = (value) => {
    const _notValidFirst = elements.some(
      (element) => element.group === "edges" && element.data.target === value.id
    );

    if (_notValidFirst) {
      toast.current.show({
        severity: "warn",
        summary: "Nodo no válido",
        detail: "Tiene flechas entrantes",
        life: 3000,
      });
      return;
    }
    updateVoicebotProp("firstQuestion", value.id);
  };

  const tipos = [
    {
      name: "Saliente",
      value: "saliente",
    },
    {
      name: "Entrante",
      value: "entrante",
    },
  ];

  /** */
  const handleInputChange = useCallback(
    (index, newValue) => {
      setEstados(
        estados.map((estado, i) =>
          i === index ? { ...estado, nombre: newValue } : estado
        )
      );
    },
    [setEstados, estados]
  );

  const handleSwitchChange = useCallback(
    (index) => {
      setEstados(
        estados.map((estado, i) =>
          i === index
            ? { ...estado, activeGraphView: !estado.activeGraphView }
            : estado
        )
      );
    },
    [setEstados, estados]
  );

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const updatedEstados = [...estados];
    const [movedItem] = updatedEstados.splice(sourceIndex, 1);
    updatedEstados.splice(destinationIndex, 0, movedItem);

    updatedEstados.forEach((estado, index) => {
      estado.ponderacion = updatedEstados.length - index - 1;
    });

    updatedEstados.sort((a, b) => b.ponderacion - a.ponderacion);
    setEstados(updatedEstados);
  };

  const renderedItems = useMemo(() => {
    return estados.map((item, index) => (
      <Draggable key={index} draggableId={index.toString()} index={index}>
        {(provided) => (
          <li
            className="drag-item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="inputs">
              <MdDragIndicator style={{ color: "#ccc", marginRight: "4px" }} />
              <span className="estado" style={{ marginRight: "6px" }}>
                {item.flechita ? (
                  <>{item.question + " - " + item.flechita}</>
                ) : (
                  <>{item.estado}</>
                )}
              </span>
              <div className="field state-name">
                <InputText
                  value={item.nombre}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  required
                  autoComplete="off"
                  placeholder="Nombre estado"
                  className={classNames({
                    "p-invalid": item.nombre === "",
                  })}
                />
                {item.nombre === "" && (
                  <small className="p-error block">{"Campo requerido"}</small>
                )}
              </div>
              <InputSwitch
                checked={item.activeGraphView}
                onChange={() => handleSwitchChange(index)}
                style={{ float: "right" }}
              />
            </div>
          </li>
        )}
      </Draggable>
    ));
  }, [estados, handleInputChange, handleSwitchChange]);

  return (
    <div className="drag-list">
      <Toast ref={toast} />
      <div className="row">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            value={voicebot.nombre}
            onChange={(e) => updateVoicebotProp("nombre", e.target.value)}
            required
            autoFocus
            autoComplete="off"
            placeholder="Nombre"
            className={classNames({
              "p-invalid": voicebot.nombre === "",
            })}
          />
          {voicebot.nombre === "" && (
            <small className="p-error block">{"Campo requerido"}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="tipo">Tipo</label>
          <Dropdown
            value={voicebot.tipo}
            onChange={(e) => updateVoicebotProp("tipo", e.value)}
            options={tipos}
            optionLabel="name"
            placeholder="Elige el tipo"
          />
        </div>

        <div className="field">
          <label htmlFor="firstQuestion">Primer nodo</label>
          <Dropdown
            value={
              nodes && nodes.find((node) => node.id === voicebot.firstQuestion)
            }
            onChange={(e) => updateFirstQuestion(e.value)}
            options={nodes}
            optionLabel="name"
            placeholder="Selecciona"
          />
        </div>

        <div className="field switch">
          <label htmlFor="tipo">Estado</label>
          <div className="field switch">
            <InputSwitch
              checked={voicebot.activo}
              onChange={(e) => updateVoicebotProp("activo", e.value)}
            />
          </div>
        </div>
      </div>

      <div className="ponderacion-indicator up">
        <AiOutlineArrowUp className="icon" /> mayor ponderación
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {renderedItems}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <div className="ponderacion-indicator down">
        <AiOutlineArrowDown className="icon" /> menor ponderación
      </div>
    </div>
  );
};

export default DynamicVoicebot;
