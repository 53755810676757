import http from "../common/components/http-common";

class LocutionService {
  ObtenerLocuciones(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        audioName: data.audioName,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/locution/", config);
  }
}

export default new LocutionService();
