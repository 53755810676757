import { create } from "zustand";

const voicebotIni = {
  id: "",
  userId: localStorage.getItem("user"),
  tipo: "saliente",
  activo: true,
  nombre: "",
  firstQuestion: null,
};

const estadosIni = [
  {
    ponderacion: 2,
    estado: "no se pudo contactar",
    nombre: "No se puede contactar",
    activeGraphView: false,
  },
  {
    ponderacion: 1,
    estado: "sin respuesta",
    nombre: "Sin respuesta",
    activeGraphView: false,
  },
  {
    ponderacion: 0,
    estado: "contestador",
    nombre: "Contestador",
    activeGraphView: false,
  },
];

const voicebotRawIni = {
  elements: [],
  estados: [],
};

export const useVoicebotStore = create((set, get) => ({
  nodeTrackers: [],
  preVoicebot: {},
  voicebotRaw: voicebotRawIni,
  voicebot: voicebotIni,
  elements: [],
  estados: estadosIni,

  setVoicebot: (voicebot) => set({ voicebot }),
  setPreVoicebot: (preVoicebot) => set({ preVoicebot }),
  setVoicebotRaw: (voicebotRaw) => set({ voicebotRaw }),
  setElements: (elements) => set({ elements }),
  setEstados: (estados) => set({ estados }),

  setNodeTrackers: (nodeTrackers) => set({ nodeTrackers }),

  updateNodeTracker: (id, updatedNodeTracker) =>
    set((state) => {
      const nodeTrackerIndex = state.nodeTrackers.findIndex(
        (nodeTracker) => nodeTracker.id === id
      );
      if (nodeTrackerIndex !== -1) {
        return {
          nodeTrackers: state.nodeTrackers.map((nodeTracker, index) => {
            if (index === nodeTrackerIndex) {
              return { ...nodeTracker, ...updatedNodeTracker };
            }
            return nodeTracker;
          }),
        };
      } else {
        return {
          nodeTrackers: [...state.nodeTrackers, { id, ...updatedNodeTracker }],
        };
      }
    }),

  addNodeTracker: (newNodeTracker) =>
    set((state) => ({
      nodeTrackers: [...state.nodeTrackers, newNodeTracker],
    })),

  removeNodeTracker: (id) =>
    set((state) => ({
      nodeTrackers: state.nodeTrackers.filter(
        (nodeTracker) => nodeTracker.id !== id
      ),
    })),

  updateNodeTrackerProp: (id, propName, propValue) =>
    set((state) => ({
      nodeTrackers: state.nodeTrackers.map((nodeTracker) => {
        if (nodeTracker.id === id) {
          return {
            ...nodeTracker,
            [propName]: propValue,
          };
        }
        return nodeTracker;
      }),
    })),

  getNodeTracker: (id) => {
    const { nodeTrackers } = get();
    return nodeTrackers.find((nodeTracker) => nodeTracker.id === id);
  },

  updateVoicebotProp: (propName, propValue) =>
    set((state) => ({
      voicebot: {
        ...state.voicebot,
        [propName]: propValue,
      },
    })),

  addElement: (newElement) =>
    set((state) => ({
      elements: [...state.elements, newElement],
    })),

  updateElement: (id, updatedElement) =>
    set((state) => ({
      elements: state.elements.map((element) => {
        if (element.data.id === id) {
          return { ...element, ...updatedElement };
        }
        return element;
      }),
    })),

  updateElementProp: (id, propName, propValue) =>
    set((state) => ({
      elements: state.elements.map((element) => {
        if (element.data.id === id) {
          return {
            ...element,
            data: {
              ...element.data,
              [propName]: propValue,
            },
          };
        }
        return element;
      }),
    })),

  getElement: (id) => {
    const { elements } = get();
    return elements.find((element) => element.data.id === id);
  },

  addEstado: (newState) =>
    set((state) => ({
      estados: [...state.estados, newState],
    })),

  updateStateNodeProp: (id, propName, propValue) =>
    set((state) => {
      return {
        estados: state.estados.map((estado) => {
          if (estado.questionId === id) {
            return {
              ...estado,
              [propName]: propValue,
            };
          }
          return estado;
        }),
      };
    }),

  updateStateEdgeProp: (id, propName, propValue) =>
    set((state) => {
      return {
        estados: state.estados.map((estado) => {
          if (estado.flechitaId === id) {
            return {
              ...estado,
              [propName]: propValue,
            };
          }
          return estado;
        }),
      };
    }),

  /** */

  setEmpty: () =>
    set(() => ({
      nodeTrackers: [],
      preVoicebot: {},
      voicebot: voicebotIni,
      voicebotRaw: voicebotRawIni,
      elements: [],
      estados: estadosIni,
    })),
}));
