import React, { useEffect } from "react";
import DynamicVoicebot from "./panelTabs/DynamicVoicebot";
import { useVoicebotStore } from "../../store/voicebotStore";

const PanelConTabs = ({
  cambiarTab,
  tabSeleccionada,
  tabNodeTemplate,
  tabEdgeTemplate,
  setTabsNull,
}) => {
  const { voicebot } = useVoicebotStore((state) => state);

  useEffect(() => {
    setTab("Voicebot");
  }, [voicebot.id]);

  const setTab = (tab) => {
    setTabsNull();
    cambiarTab(tab);
  };

  return (
    <div className="panel-tabs">
      <div className="tabs-container">
        <button
          className="tab"
          onClick={() => setTab("Voicebot")}
          style={{
            backgroundColor:
              tabSeleccionada === "Voicebot" ? "#2196F3" : "transparent",
            color: tabSeleccionada === "Voicebot" ? "white" : "#495057",
          }}
        >
          Voicebot
        </button>
        <button
          className="tab"
          style={{
            backgroundColor:
              tabSeleccionada === "Nodo" ? "#2196F3" : "transparent",
            color: tabSeleccionada === "Nodo" ? "white" : "#495057",
          }}
        >
          Nodo
        </button>
        <button
          className="tab"
          style={{
            backgroundColor:
              tabSeleccionada === "Flecha" ? "#2196F3" : "transparent",
            color: tabSeleccionada === "Flecha" ? "white" : "#495057",
          }}
        >
          Flecha
        </button>
      </div>
      <div style={{ flex: 1 }}>
        {tabSeleccionada === "Nodo" && <div>{tabNodeTemplate}</div>}
        {tabSeleccionada === "Voicebot" && (
          <div>
            <DynamicVoicebot />
          </div>
        )}
        {tabSeleccionada === "Flecha" && <div>{tabEdgeTemplate}</div>}
      </div>
    </div>
  );
};

export default PanelConTabs;
